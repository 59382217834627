import * as React from 'react';
import { Tag as AntTag } from 'antd';
import "./Tag.scss";

interface Props {
    asLink?: boolean;
    children: React.ReactNode;
}

const Tag = (props: Props) => {
    return <AntTag color="geekblue" className={props.asLink ? "tag tag--isLink" : ""} >{props.children}</AntTag>;
};

export default Tag;
