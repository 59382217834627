import * as React from "react";
import { Modal } from "antd";
import Input from "../../../components/atoms/Input/Input";
import "./AddStream.scss";
import Checkbox from "../../../components/atoms/Checkbox/Checkbox";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { postStream } from "../actions";
import { catchHttpErros, throwHttpErros } from "../../../utils/httpErrors";
import * as validator from "validator";
import { Group } from "../../Groups/types";
import CheckboxGroup from "../../../components/atoms/CheckboxGroup/CheckboxGroup";

interface Props {
  groups: Group[];
  onDone: () => void;
}

interface State {
  isLoading: boolean;
  isValid: boolean;
  archived: boolean;
  name: string;
  url: string;
  groups: Group[];
}

class AddStream extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      isLoading: false,
      isValid: false,
      archived: false,
      name: "",
      url: "",
      groups: []
    };
  }

  public onSubmit = () => {
    this.setState({ isLoading: true });
    const { name, url, archived, groups } = this.state;
    postStream({
      name,
      url,
      archived,
      groups
    })
      .then(throwHttpErros)
      .then(() => {
        this.props.onDone();
      })
      .catch(catchHttpErros)
      .then(response => {
        if (response && !response.ok) {
          this.setState({ isLoading: false });
        }
      });
  };

  public render() {
    return (
      <Modal
        visible={true}
        title="Stream hinzufügen"
        onOk={this.onSubmit}
        onCancel={this.props.onDone}
        okButtonProps={{
          disabled: !this.state.isValid || this.state.isLoading
        }}
        confirmLoading={this.state.isLoading}
        className="add-stream"
      >
        <div className="add-stream__form">
          <Input
            id="name"
            label="Sender"
            value={this.state.name}
            placeholder="Name des Senders/Streams"
            onChange={this.onChange}
          />
          <Input
            id="url"
            label="URL"
            value={this.state.url}
            placeholder="Stream-URL"
            onChange={this.onChange}
          />
          <div className="legal">Keine .m3u Dateien, kein HTTPS</div>
          <Checkbox
            name="archived"
            checked={this.state.archived}
            onChange={this.onChangeBoolean}
          >
            Keine neuen Mitschnitte anlegen
          </Checkbox>
        </div>
        <div className="add-stream__groups">
          <h4>Gruppen</h4>
          <CheckboxGroup
              options={this.props.groups.map(group => group.name)}
              value={this.state.groups.map(group => group.name)}
            onChange={this.onChangeGroups}
          />
        </div>
      </Modal>
    );
  }

  private onChange = (name: "name" | "url", value: string) => {
    // @ts-ignore ToDo: Fix with typescript aligned
    this.setState({ [name]: value }, () => {
      this.isValid();
    });
  };

  private onChangeBoolean = (e: CheckboxChangeEvent) => {
    // @ts-ignore ToDo: Fix with typescript aligned
    this.setState({ [e.target.name]: e.target.checked }, () => {
      this.isValid();
    });
  };

  private onChangeGroups = (names: string[]) => {
    const groups = names.reduce((list: Group[], name: string) => {
      const group = this.props.groups.find(x => x.name === name);
      if (group) {
        return [...list, group];
      } else {
        return list;
      }
    }, []);
    this.setState({ groups }, () => {
      this.isValid();
    });
  };

  private isValid = () => {
    const isValid =
      validator.isLength(this.state.name, { min: 3 }) &&
      validator.isURL(this.state.url);
    if (isValid !== this.state.isValid) {
      this.setState({ isValid });
    }
  };
}

export default AddStream;
