import * as React from "react";
import LoadingState from "../../components/atoms/Loading/LoadingState";
import { StreamContext, StreamContextInterface } from "./context";
import { Stream } from "./types";
import Table from "../../components/organisms/Table/Table";
import Tag from "../../components/atoms/Tag/Tag";
import { Group } from "../Groups/types";

interface Props {
  mode: "link" | "edit";
  onClick?: (ID: string) => void;
}

interface State {}

class StreamList extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);
  }

  public getColumns = (context: StreamContextInterface) => {
    return [
      {
        title: "Sender",
        dataIndex: "stream",
        key: "stream"
      },
      {
        title: "Gruppen",
        dataIndex: "groups",
        key: "groups",
        filters: context.groups.map((group: Group) => ({
          text: group.name,
          value: group.name
        })),
        onFilter: (filter: string, record: any) => {
          return record.groups.find((x: Group) => x.name === filter);
        },
        render: (groups: Group[]) => (
          <>
            {groups.map((group: Group) => (
              <Tag key={group.ID}>{group.name.toUpperCase()}</Tag>
            ))}
          </>
        )
      }
    ];
  };

  public mapStreams = (streams: Stream[]) => {
    return streams.map((stream: Stream) => {
      return {
        key: stream.ID,
        stream: stream.name,
        groups: stream.groups,
        entity: stream
      };
    });
  };

  public onRow = (record: { entity: Stream }) => {
    return {
      onClick: () => this.props.onClick && this.props.onClick(record.entity.ID)
    };
  };

  public render() {
    return (
      <StreamContext.Consumer>
        {context =>
          context && context.streams.length > 0 ? (
            <Table
              columns={this.getColumns(context)}
              dataSource={this.mapStreams(context.streams)}
              onRow={this.onRow}
              isLinkList={true}
            />
          ) : (
            <LoadingState size="l" text="Lade Streams" />
          )
        }
      </StreamContext.Consumer>
    );
  }
}

export default StreamList;
