import * as React from "react";
import { Modal } from "antd";
import Input from "../../../components/atoms/Input/Input";
import Checkbox from "../../../components/atoms/Checkbox/Checkbox";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Group, TeamMember } from "../types";
import { putTeamMember } from "../actions";
import { catchHttpErros, throwHttpErros } from "../../../utils/httpErrors";
import * as validator from "validator";
import CheckboxGroup from "../../../components/atoms/CheckboxGroup/CheckboxGroup";

interface Props {
  groups: Group[];
  onDone: () => void;
  user: TeamMember;
}

interface State {
  isLoading: boolean;
  isValid: boolean;
  admin: boolean;
  username: string;
  email: string;
  password: string;
  groups: Group[];
}

class EditTeamMember extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      isLoading: false,
      isValid: false,
      admin: this.props.user.admin,
      username: this.props.user.username,
      email: this.props.user.email,
      password: "",
      groups: this.props.user.groups
    };
  }

  public onSubmit = () => {
    this.setState({ isLoading: true });
    const { username, email, admin, password, groups } = this.state;
    putTeamMember({
      ID: this.props.user.ID,
      username,
      email,
      password: password ? password : undefined,
      admin,
      groups
    })
      .then(throwHttpErros)
      .then(() => {
        this.props.onDone();
      })
      .catch(catchHttpErros)
      .then(response => {
        if (response && !response.ok) {
          this.setState({ isLoading: false });
        }
      });
  };

  public render() {
    return (
      <Modal
        visible={true}
        title="Team Mitglied bearbeiten"
        onOk={this.onSubmit}
        onCancel={this.props.onDone}
        okButtonProps={{
          disabled: !this.state.isValid || this.state.isLoading
        }}
        confirmLoading={this.state.isLoading}
        className="add-team-member"
      >
        <div className="add-team-member__form">
          <Input
            id="username"
            label="Name"
            value={this.state.username}
            placeholder="Benutzername"
            onChange={this.onChange}
          />
          <Input
            id="email"
            label="E-Mail"
            value={this.state.email}
            placeholder="E-Mail Adresse"
            onChange={this.onChange}
          />
          <Input
            id="password"
            label="Kennwort"
            value={this.state.password}
            type="password"
            placeholder="Eigenes Kennwort"
            onChange={this.onChange}
          />
          <div className="legal">
            Kennwort wird geändert, wenn ein neues Kennwort eingegeben wurde.
            Das neue Kennwort muss mindestens 10 Zeichen lang sein.
          </div>
          <Checkbox
            name="admin"
            checked={this.state.admin}
            onChange={this.onChangeBoolean}
          >
            Administrator
          </Checkbox>
        </div>
        <div className="add-team-member__groups">
          <h4>Gruppen</h4>
          <CheckboxGroup
            options={this.props.groups.map(group => group.name)}
            value={this.state.groups.map(group => group.name)}
            onChange={this.onChangeGroups}
          />
        </div>
      </Modal>
    );
  }

  private onChange = (name: "email" | "username", value: string) => {
    // @ts-ignore ToDo: Fix with typescript aligned
    this.setState({ [name]: value }, () => {
      this.isValid();
    });
  };

  private onChangeBoolean = (e: CheckboxChangeEvent) => {
    // @ts-ignore ToDo: Fix with typescript aligned
    this.setState({ [e.target.name]: e.target.checked }, () => {
      this.isValid();
    });
  };

  private onChangeGroups = (names: string[]) => {
    const groups = names.reduce((list: Group[], name: string) => {
      const group = this.props.groups.find(x => x.name === name);
      if (group) {
        return [...list, group];
      } else {
        return list;
      }
    }, []);
    this.setState({ groups }, () => {
      this.isValid();
    });
  };

  private isValid = () => {
    const isValidPassword =
      this.state.password !== ""
        ? validator.isLength(this.state.password, 10)
        : true;
    const isValid =
      validator.isEmail(this.state.email) &&
      validator.isLength(this.state.username, 3) &&
      isValidPassword;
    if (isValid !== this.state.isValid) {
      this.setState({ isValid });
    }
  };
}

export default EditTeamMember;
