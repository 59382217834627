import * as React from "react";
import Router from "./features/Navigation/Router";

import "./styles/fonts.scss";

class App extends React.Component {
  public render() {
    return <Router />;
  }
}

export default App;
