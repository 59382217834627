import * as React from 'react';
import { Table as AntTable } from "antd";
import { TableProps } from "node_modules/antd/lib/table/interface";
import './Table.scss';

export interface TableFilterOption {
    text: string;
    value: string;
}

interface Props extends TableProps<any> {
    isLinkList?: boolean;
}

class Table extends React.Component<Props> {

    public renderCustomRowClass = () => {
        if (this.props.isLinkList) {
            return "table__row--is-link";
        } else {
            return "";
        }
    };



    public render() {

        const { isLinkList, ...rest } = this.props;

        const props = {
            locale: {
                filterTitle: "Filter",
                filterConfirm: "Ok",
                filterReset: "Abbr.",
                emptyText: "Keine passenden Ergebnisse"
            },
            ...rest
        };

        return <AntTable pagination={false} onRow={this.props.onRow} rowClassName={this.renderCustomRowClass} {...props} />;
    }
}

export default Table;
