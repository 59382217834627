import {get, catchActionErrors, throwActionErrors, post, put, del} from '../../utils/actions';
import {NewStream, Stream} from "./types";

export function getStreams() {
  return get(`/api/streams`)
    .then(throwActionErrors)
    .then(response => {
      return response.json();
    })
    .catch(catchActionErrors);
}

export function postStream(body: NewStream) {
    return post(`/api/streams`, body)
        .then(throwActionErrors)
        .then(response => {
            return response.json();
        })
        .catch(catchActionErrors);
}

export function putStream(body: Stream) {
    return put(`/api/streams/${body.ID}`, body)
        .then(throwActionErrors)
        .then(response => {
            return response.json();
        })
        .catch(catchActionErrors);
}

export function deleteStream(id: string) {
    return del(`/api/streams/${id}`)
        .then(throwActionErrors)
        .then(response => {
            return response.json();
        })
        .catch(catchActionErrors);
}