export function set(key: string, value: string) {
    return localStorage.setItem(key, value);
}

export function setObject(key: string, object: any) {
    return localStorage.setItem(key, JSON.stringify(object));
}

export function get(key: string) {
    const value = localStorage.getItem(key);
    return value === 'undefined' ? undefined : value;
}

export function getObject(key: string) {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : undefined;
}

export function del(key: string) {
    return localStorage.removeItem(key);
}

export function clear() {
    return localStorage.clear();
}