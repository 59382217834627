import * as React from "react";
import Button from "../../components/atoms/Button/Button";
import "./Logout.scss";
import { logout } from "./actions";
import { history } from "../Navigation/history";
import * as storage from "../../utils/localStorage";
import Icon from "../../components/atoms/Icon/Icon";

interface Props {}

interface State {
  isLoading: boolean;
}

class Logout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  public render() {
    return (
      <div className="logout">
        <h1>Tschüss 👋</h1>
        {this.state.isLoading ? (
          <div className="logout__status">
            <Icon type="loading" />
            <span className="logout__text logout__text--loading">Logout läuft</span>
          </div>
        ) : (
            <div className="logout__status">
              <span className="logout__text">Fertig ausgeloggt.</span>
            </div>
        )}
        <div className="logout__options">
          <Button disabled={this.state.isLoading} onClick={this.onReturn}>Weiter zum Login</Button>
        </div>
      </div>
    );
  }

  public componentDidMount(): void {
    logout()
      .then(() => {
        this.setState({ isLoading: false });
        storage.del('token');
      });
  }

  private onReturn = () => {
    history.push("/");
  };
}

export default Logout;
