import * as React from 'react';
import './Content.scss';

const Content: React.FunctionComponent = (props) => (
    <div className="content">
        {props.children}
    </div>
);

export default Content;
