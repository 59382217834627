import * as React from "react";
import { Link } from "react-router-dom";
import StreamList from "../Streams/StreamList";
import { history } from "../Navigation/history";
import * as storage from "../../utils/localStorage";
import Tag from "../../components/atoms/Tag/Tag";

interface Props {}

interface State {}

class RecordingsDashboard extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);
  }

  public onClick = (id: string) => {
    history.push(`/recordings/${id}`);
  };

  public render() {
    const token = (storage.get('token') || 'unknown.unknown.unknown').split('.')[2];
    const favorites = storage.getObject(`streamHistory_${token}`);

    return (
      <>
        {favorites ? (
          <>
            <h2>Mitschnitte: Meine Lieblingssender</h2>
            {favorites.map((stream: any) => {
              return <Link key={stream.id} to={`/recordings/${stream.id}`}><Tag asLink={true}>{stream.name}</Tag></Link>;
            })}
          </>
        ) : (
          <>
            <h2>Mitschnitte: Sender</h2>
            <StreamList mode="link" onClick={this.onClick} />
          </>
        )}
      </>
    );
  }
}

export default RecordingsDashboard;
