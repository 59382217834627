import * as React from "react";
import "./AudioPlayer.scss";

interface Props {
  url: string;
}

const AudioPlayer = (props: Props) => {

  return <audio preload="metadata" className="audio-player" src={props.url} controls={true} controlsList="nodownload" />;
};

export default AudioPlayer;
