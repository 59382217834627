import * as React from 'react';
import { Button as AntButton } from 'antd';
import "./Button.scss";

interface Props {
    type: 'primary' | 'default' | 'ghost' | 'danger'
    onClick?: any;
    children: React.ReactNode;
    fullWidth?: boolean;
    loading?: boolean;
    size?: 'small' | 'large';
}

const Button = (props: Props) => {

    const {fullWidth, children, ...rest} = props;
    const newProps = {
        ...rest,
        block: fullWidth || undefined,
    };

    return <AntButton {...newProps} >{children}</AntButton>;
};

Button.defaultProps = {
    type: 'primary',
    disabled: false,
};

export default Button;
