import * as React from "react";
import "./HeadlineOptions.scss";

interface Props {
  left: React.ReactNode;
  right: React.ReactNode;
}

const HeadlineOptions = (props: Props) => {
  return (
    <div className="headline-options">
      <div className="headline-options__left">{props.left}</div>
      <div className="headline-options__right">{props.right}</div>
    </div>
  );
};

export default HeadlineOptions;
