import * as React from "react";
import { Modal } from "antd";
import { Stream } from "../types";
import { deleteStream } from "../actions";
import { catchHttpErros, throwHttpErros } from "../../../utils/httpErrors";

interface Props {
  stream: Stream;
  onDone: () => void;
}

interface State {
  isLoading: boolean;
}

class DeleteStream extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      isLoading: false
    };
  }

  public onSubmit = () => {
    this.setState({ isLoading: true });
    deleteStream(this.props.stream.ID)
      .then(throwHttpErros)
      .then(() => {
        this.props.onDone();
      })
      .catch(catchHttpErros)
      .then(response => {
        if (response && !response.ok) {
          this.setState({ isLoading: false });
        }
      });
  };

  public render() {
    return (
      <Modal
        visible={true}
        title="Stream löschen"
        okType="danger"
        onOk={this.onSubmit}
        onCancel={this.props.onDone}
        confirmLoading={this.state.isLoading}
      >
        Soll <strong>{this.props.stream.name}</strong> wirklich gelöscht werden?
      </Modal>
    );
  }
}

export default DeleteStream;
