import * as React from 'react';
import './Page.scss';

interface Props {
    children: React.ReactNode;
}

const Page = (props: Props) => (
    <div className="page">
        {props.children}
        <div className="page__version legal">
            v{process.env.REACT_APP_VERSION}
        </div>
    </div>
);

export default Page;