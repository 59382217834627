import { get, catchActionErrors, throwActionErrors} from '../../utils/actions';

export function getGroups() {
  return get(`/api/groups`)
    .then(throwActionErrors)
    .then(response => {
      return response.json();
    })
    .catch(catchActionErrors);
}
