import * as React from "react";
import { getGroups } from "../actions";
import {throwHttpErros, catchHttpErros} from "../../../utils/httpErrors";
import { GroupContext, GroupContextInterface } from "../context";

interface Props {}

interface State extends GroupContextInterface {}

class GroupContextProvider extends React.Component<Props, State> {
    constructor(props: Props, context: any) {
        super(props, context);

        this.state = {
            groups: [],
        };
    }

    public componentDidMount(): void {
        getGroups()
            .then(throwHttpErros)
            .then(response => {
                this.setState({
                    groups: response,
                });
            })
            .catch(catchHttpErros);
    }

    public componentWillUnmount(): void {
        this.setState({groups: []})
    }

    public render() {
        return <GroupContext.Provider value={this.state}>
            {this.props.children}
        </GroupContext.Provider>
    }
}

export default GroupContextProvider;
