import * as React from "react";
import { Segment } from "../types";
import Button from "../../../components/atoms/Button/Button";
import * as storage from "../../../utils/localStorage";

interface StreamHistory {
  name: string;
  id: string;
  count: number;
}

interface Props {
  streamId: string;
  streamName: string;
  segment: Segment;
}

class DownloadButton extends React.Component<Props> {
  constructor(props: Props, context: any) {
    super(props, context);
  }

  public updateHistory = () => {
    const token = (storage.get('token') || 'unknown.unknown.unknown').split('.')[2];
    const history = storage.getObject(`streamHistory_${token}`);
    let newHistory: StreamHistory[] = [];
    const newEntry = {
      id: this.props.streamId,
      name: this.props.streamName,
      count: 1,
    };

    if (!history) {
      newHistory = [newEntry];
    } else {
      const currentStream: StreamHistory = history.find((x: StreamHistory) => x.id === this.props.streamId);
      if (currentStream) {
        const otherStreams = history.filter((x: StreamHistory) => x.id !== this.props.streamId);
        newHistory = [
          ...otherStreams,
          {
            id: currentStream.id,
            name: this.props.streamName,
            count: currentStream.count + 1,
          }
        ];
      } else {
        newHistory = [
            ...history,
            newEntry
        ];
      }
    }

    storage.setObject(`streamHistory_${token}`, newHistory)
  };

  public onDownload = () => {
    this.updateHistory();
    const { segment } = this.props;
    window.location.href = `${segment.url}?download=true&jwt=${storage.get('token')}`;
  };

  public render() {
    return (
      <Button
        type="default"
        onClick={this.onDownload}
      >
        Download
      </Button>
    );
  }
}

export default DownloadButton;
