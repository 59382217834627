import * as React from 'react';
import { Icon as AntIcon } from 'antd';

interface Props {
    type: any;
}

const Icon = (props: Props) => {
    return <AntIcon {...props} />;
};

export default Icon;
