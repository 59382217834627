import * as React from "react";
import { Icon as AntIcon } from "antd";
import "./LoadingState.scss";

interface Props {
  size?: "m" | "l";
  text?: string;
}

const LoadingState = (props: Props) => {
  const classNames = ["loading-state"];
  if (props.size === "l") {
    classNames.push("loading-state--large");
  }

  return (
    <div className={classNames.join(' ')}>
      <AntIcon type="loading" style={{ fontSize: 25 }} />
      {props.text ? (
        <div className="loading-state__text">{props.text}</div>
      ) : null}
    </div>
  );
};

export default LoadingState;
