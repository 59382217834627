import * as React from "react";
import Content from "../../components/organisms/Content/Content";
import LoadingState from "../../components/atoms/Loading/LoadingState";
import { getRecordings } from "./actions";
import { throwHttpErros, catchHttpErros } from "../../utils/httpErrors";
import { StreamRecordings } from "./types";
import { RecordingDay, Segment } from "./types";
import Table from "../../components/organisms/Table/Table";
import "./Recordings.scss";
import AudioPlayer from "../../components/atoms/AudioPlayer/AudioPlayer";
import Moment from "react-moment";
import DownloadButton from "./DownloadButton/DownloadButton";
import * as storage from "../../utils/localStorage";

interface Props {
  streamId: string;
}

interface State {
  isLoading: boolean;
  stream: StreamRecordings | null;
}

class Recordings extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      isLoading: true,
      stream: null,
    };
  }

  public componentDidMount(): void {
    getRecordings(this.props.streamId)
        .then(throwHttpErros)
        .then(response => {
          this.setState({
            isLoading: false,
            stream: response,
          });
        })
        .catch(catchHttpErros);
  }

  public getColumns = () => {
    return [
      {
        title: "Tag/ Uhrzeit",
        dataIndex: "day",
        key: "day"
      },
      {
        title: "Aufnahme",
        dataIndex: "record",
        key: "record"
      },
    ];
  };

  public getRows = (recordings: RecordingDay[]) => {
    return recordings.map((day: RecordingDay) => {
      return {
        key: day.date,
        day: <Moment format="L">{day.date}</Moment>,
        segments: day.segments
      };
    });
  };

  public getRowChildren = (record: any) => {
    if (!record.segments) {
      return null;
    }
    return record.segments.map((segment: Segment) => (
      <div className="segment" key={`segment-${this.props.streamId}-${segment.startDate}`}>
        <div className="segment__time">
          <Moment format="HH:mm">{segment.startDate}</Moment> bis <Moment format="HH:mm">{segment.endDate}</Moment>
        </div>
        <div className="segment__player"><AudioPlayer url={`${segment.url}?jwt=${storage.get('token')}`}/>
          <div className="segment__download">
            <DownloadButton streamName={this.state.stream ? this.state.stream.name : ""} streamId={this.props.streamId} segment={segment}/>
          </div>
        </div>
      </div>
    ));
  };

  public render() {
    if (this.state.isLoading || !this.state.stream) {
      return (
        <Content>
          <LoadingState size="l" text="Lade Mitschnitte" />
        </Content>
      );
    }

    return (
      <Content>
        <h1>{this.state.stream.name}</h1>
        <Table
          columns={this.getColumns()}
          dataSource={this.getRows(this.state.stream.recordings)}
          expandedRowRender={this.getRowChildren}
        />
      </Content>
    );
  }
}

export default Recordings;
