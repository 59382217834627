import * as React from "react";
import Content from "../../components/organisms/Content/Content";
import StreamList from "../Streams/StreamList";
import {history} from "../Navigation/history";

interface Props {}

interface State {}

class RecordingsStreamList extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);
  }

  public onClick = (id: string) => {
    history.push(`/recordings/${id}`);
  };

  public render() {
    return (
      <Content>
        <h1>Mitschnitte: Sender</h1>
        <StreamList mode="link" onClick={this.onClick}/>
      </Content>
    );
  }
}

export default RecordingsStreamList;
