import {get, catchActionErrors, throwActionErrors} from '../../utils/actions';

export function login(username: string, password: string) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ username, password })
  };

  return fetch(`/api/users/login`, options)
    .then(throwActionErrors)
    .then(response => {
      return response.json();
    })
    .catch(catchActionErrors);
}

export function logout() {
  return get(`/api/users/logout`)
    .then(throwActionErrors)
    .then(() => {
      return {ok: true};
    })
    .catch(catchActionErrors);
}
