import * as React from "react";
import Input from "../../components/atoms/Input/Input";
import Button from "../../components/atoms/Button/Button";
import "./Login.scss";

interface Props {
  onLogin: (email: string, password: string, onReset: () => void) => void;
}

interface State {
  isLoading: boolean;
  email: string;
  password: string;
}

class Login extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
      password: ""
    };
  }

  public render() {
    const { isLoading } = this.state;

    return (
      <div className="login">
        <h1>Login</h1>
        <div className="login__form">
          <Input
            id="email"
            label="E-Mail"
            value={this.state.email}
            placeholder="E-Mail Adresse"
            onChange={this.onChange}
            onReturn={this.onLogin}
          />
          <Input
            id="password"
            label="Kennwort"
            value={this.state.password}
            placeholder="Kennwort"
            onChange={this.onChange}
            onReturn={this.onLogin}
            type="password"
          />
          <Button
            type="primary"
            loading={isLoading}
            disabled={isLoading}
            fullWidth={true}
            onClick={this.onLogin}
          >
            Anmelden
          </Button>
        </div>
      </div>
    );
  }

  private onChange = (name: "email" | "password", value: string) => {
    // @ts-ignore ToDo: Fix with typescript aligned
    this.setState({ [name]: value });
  };

  private onLogin = () => {
    this.setState({ isLoading: true }, () => {
      this.props.onLogin(this.state.email, this.state.password, () => {
        this.setState({ isLoading: false });
      });
    });
  };
}

export default Login;
