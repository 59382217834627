import { get, post, put, del, catchActionErrors, throwActionErrors} from '../../utils/actions';
import {NewTeamMember, TeamMember} from "./types";

export function getTeam() {
  return get(`/api/users`)
    .then(throwActionErrors)
    .then(response => {
      return response.json();
    })
    .catch(catchActionErrors);
}

export function postTeamMember(body: NewTeamMember) {
  return post(`/api/users`, body)
    .then(throwActionErrors)
    .then(response => {
      return response.json();
    })
    .catch(catchActionErrors);
}

export function putTeamMember(body: TeamMember) {
  return put(`/api/users/${body.ID}`, body)
    .then(throwActionErrors)
    .then(response => {
      return response.json();
    })
    .catch(catchActionErrors);
}

export function deleteTeamMember(id: string) {
  return del(`/api/users/${id}`)
    .then(throwActionErrors)
    .then(response => {
      return response.json();
    })
    .catch(catchActionErrors);
}
