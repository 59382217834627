import * as React from 'react';
import StreamContextProvider from "./Streams/StreamContextProvider/StreamContextProvider";
import GroupContextProvider from "./Groups/GroupContextProvider/GroupContextProvider";

interface Props {
    children: React.ReactNode;
}

const Contexts = (props: Props) => (
    <StreamContextProvider>
        <GroupContextProvider>
            {props.children}
        </GroupContextProvider>
    </StreamContextProvider>
);

export default Contexts;