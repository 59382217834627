import notification from "../components/atoms/Notification/notification";
import {history} from "../features/Navigation/history";

export interface HttpError {
    ok: boolean;
    status: number;
    statusText: string;
    body: any;
}

export function throwHttpErros(response: any) {
    if (response.hasOwnProperty('ok') && response.ok === false) {
        throw response;
    }
    return response;
}

export function catchHttpErros(error: HttpError) {
    let errorHeadline = `Fehler ${error.status}`;
    let errorText = `Es ist ein Fehler aufgetreten (${error.statusText}).`;
    switch (error.status) {
        case 401:
            history.push("/logout");
            errorHeadline = 'Keine Berechtigung: 401';
            errorText = 'Sie sind nicht (mehr) eingeloggt. Bitte melden Sie sich erneut an.';
            break;
        case 403:
            errorHeadline = 'Keine Berechtigung: 403';
            errorText = 'Sie haben keine Berechtigungen für diesen Bereich.';
            break;
        default:
    }

    notification({
        type: "error",
        message: errorHeadline,
        description: errorText
    });

    return error;
}