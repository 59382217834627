import * as React from "react";
import Auth from "../Auth/Auth";
import Login from "../Auth/Login";
import Navigation from "../Navigation/Navigation";
import { login } from "./actions";
import * as storage from "../../utils/localStorage";
import { AuthContext, AuthContextInterface, TokenPayload } from "./context";
import notification from "../../components/atoms/Notification/notification";
import {throwHttpErros} from "../../utils/httpErrors";
import Contexts from "../Contexts";

interface Props {
  children: React.ReactNode;
}

interface State extends AuthContextInterface {}

class PrivateRoute extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const token = storage.get("token") || "";
    const user = token ? this.tokenToUser(token) : null;

    this.state = {
      isAuthenticated: !!token,
      token,
      user,
    };
  }

  public render() {
    return this.state.isAuthenticated ? (
      <>
        <AuthContext.Provider value={this.state}>
          <Contexts>
            <Navigation />
            {this.props.children}
          </Contexts>
        </AuthContext.Provider>
      </>
    ) : (
      <Auth>
        <Login onLogin={this.onLogin} />
      </Auth>
    );
  }

  private tokenToUser(token: string): TokenPayload {
    const jwt = token.split('.');
    return JSON.parse(atob(jwt[1]));
  }

  private onLogin = (email: string, password: string, onReset: () => void) => {
    login(email, password)
      .then(throwHttpErros)
      .then(response => {
        if (response.token) {
          const { token } = response;
          storage.set("token", token);
          this.setState({
            isAuthenticated: true,
            token,
            user: this.tokenToUser(token)
          });
        } else {
          throw response;
        }
      })
      .catch(error => {
        onReset();
        let errorText = `Systemfehler ${
          error.status
        }: Bitte versuchen Sie es später noch einmal.`;
        switch (error.status) {
          case 401:
          case 403:
            errorText = "E-Mail Adresse oder Kennwort sind falsch.";
            break;
          default:
        }

        notification({
          type: "error",
          message: "Fehler beim Login",
          description: errorText
        });
      });
  };
}

export default PrivateRoute;
