import * as React from "react";
import LoadingState from "../../components/atoms/Loading/LoadingState";
import Content from "../../components/organisms/Content/Content";
import { Stream } from "./types";
import { catchHttpErros, throwHttpErros } from "../../utils/httpErrors";
import { getStreams } from "./actions";
import Table from "../../components/organisms/Table/Table";
import HeadlineOptions from "../../components/molecules/HeadlineOptions/HeadlineOptions";
import Button from "../../components/atoms/Button/Button";
import Tag from "../../components/atoms/Tag/Tag";
import DeleteStream from "./DeleteStream/DeleteStream";
import { GroupContext } from "../Groups/context";
import AddStream from "./AddStream/AddStream";
import EditStream from "./EditStream/EditStream";
import { Group } from "../Groups/types";

interface Props {}

interface State {
  isLoading: boolean;
  modal: "add" | "edit" | "delete" | null;
  modalEntity: Stream | null;
  streams: Stream[];
}

class Streams extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      isLoading: true,
      modal: null,
      modalEntity: null,
      streams: []
    };
  }

  public getColumns = (rows: Stream[]) => {
    return [
      {
        title: "Sender",
        dataIndex: "stream",
        key: "stream"
      },
      {
        title: "Gruppen",
        dataIndex: "groups",
        key: "groups",
        filters: rows
          .reduce((list: string[], item: Stream) => {
            const newGroups = item.groups
              .map((group: Group) => group.name)
              .filter(x => !list.includes(x));
            return [...list, ...newGroups];
          }, [])
          .map(group => ({ text: group, value: group })),
        onFilter: (filter: string, record: any) => {
          return record.groups.find((x: Group) => x.name === filter);
        },
        render: (groups: Group[]) => (
          <>
            {groups.map(group => (
              <Tag key={group.ID}>{group.name}</Tag>
            ))}
          </>
        )
      },
      {
        title: "Aktionen",
        dataIndex: "entity",
        key: "entity",
        width: 250,
        render: (entity: Stream) => (
          <>
            <Button
              type="default"
              onClick={this.onOpenEditModal.bind(this, entity)}
            >
              Bearbeiten
            </Button>
            <Button
              type="danger"
              onClick={this.onOpenDeleteModal.bind(this, entity.ID)}
            >
              Löschen
            </Button>
          </>
        )
      }
    ];
  };

  public mapRows = (rows: Stream[]) => {
    return rows.map((stream: Stream) => {
      return {
        key: stream.ID,
        stream: stream.name,
        groups: stream.groups,
        entity: stream
      };
    });
  };

  public componentDidMount(): void {
    this.fetchStreams();
  }

  public fetchStreams = () => {
    getStreams()
      .then(throwHttpErros)
      .then(response => {
        this.setState({
          isLoading: false,
          streams: response
        });
      })
      .catch(catchHttpErros);
  };

  public onCloseModal = () => {
    this.setState({ modal: null, modalEntity: null });
  };

  public onOpenAddModal = () => {
    this.setState({ modal: "add" });
  };

  public onOpenDeleteModal = (id: string) => {
    const modalEntity = this.state.streams.find(x => x.ID === id) || null;
    this.setState({ modal: "delete", modalEntity });
  };

  public onOpenEditModal = (modalEntity: Stream) => {
    this.setState({ modal: "edit", modalEntity });
  };

  public onDone = () => {
    this.onCloseModal();
    this.fetchStreams();
  };

  public renderModal = () => {
    switch (this.state.modal) {
      default:
        return null;
      case "add":
        return (
          <GroupContext.Consumer>
            {context =>
              context && context.groups.length > 0 ? (
                <AddStream groups={context.groups} onDone={this.onDone} />
              ) : null
            }
          </GroupContext.Consumer>
        );
      case "delete":
        const { modalEntity } = this.state;
        return (
          modalEntity && (
            <DeleteStream stream={modalEntity} onDone={this.onDone} />
          )
        );
      case "edit":
        return (
          <GroupContext.Consumer>
            {context =>
              context && context.groups.length > 0 && this.state.modalEntity ? (
                <EditStream
                  stream={this.state.modalEntity}
                  groups={context.groups}
                  onDone={this.onDone}
                />
              ) : null
            }
          </GroupContext.Consumer>
        );
    }
  };

  public render() {
    const { isLoading } = this.state;

    return (
      <Content>
        <HeadlineOptions
          left={<h1>Streams</h1>}
          right={
            <Button
              type="primary"
              size="small"
              disabled={this.state.modal === "add"}
              onClick={this.onOpenAddModal}
            >
              Hinzufügen
            </Button>
          }
        />
        {isLoading ? (
          <LoadingState size="l" text="Lade Streams" />
        ) : (
          <>
            {this.renderModal()}
            <Table
              columns={this.getColumns(this.state.streams)}
              dataSource={this.mapRows(this.state.streams)}
            />
          </>
        )}
      </Content>
    );
  }
}

export default Streams;
