import * as React from "react";
import { Modal } from "antd";
import {TeamMember} from "../types";
import { deleteTeamMember } from "../actions";
import { catchHttpErros, throwHttpErros } from "../../../utils/httpErrors";

interface Props {
  teamMember: TeamMember;
  onDone: () => void;
}

interface State {
  isLoading: boolean;
}

class DeleteTeamMember extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      isLoading: false,
    };
  }

  public onSubmit = () => {
    this.setState({isLoading: true});
    deleteTeamMember(this.props.teamMember.ID)
      .then(throwHttpErros)
      .then(() => {
        this.props.onDone();
      })
      .catch(catchHttpErros)
      .then((response) => {
        if (response && !response.ok) {
          this.setState({isLoading: false})
        }
      });
  };

  public render() {
    return (
      <Modal
        visible={true}
        title="Team Mitglied löschen"
        okType="danger"
        onOk={this.onSubmit}
        onCancel={this.props.onDone}
        confirmLoading={this.state.isLoading}
      >
        Soll <strong>{this.props.teamMember.username}</strong> wirklich gelöscht werden?
      </Modal>
    );
  }
}

export default DeleteTeamMember;
