import * as React from "react";

export interface TokenPayload {
    ID: string;
    exp: number;
    isAdmin: boolean;
    permissions: string[];
    iss: string;
}

export interface AuthContextInterface {
    isAuthenticated: boolean;
    token: string;
    user: TokenPayload | null;
}

export const AuthContext = React.createContext<AuthContextInterface | null>(null);