import * as React from 'react';
import { Checkbox as AntCheckbox } from 'antd';
import { CheckboxProps } from "node_modules/antd/lib/checkbox";

interface Props extends CheckboxProps {}

const Checkbox = (props: Props) => {
    const {children, ...rest} = props;
    return <AntCheckbox {...rest} >{children}</AntCheckbox>;
};

Checkbox.defaultProps = {};

export default Checkbox;
