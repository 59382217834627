import * as React from "react";
import { getStreams } from "../actions";
import { throwHttpErros, catchHttpErros } from "../../../utils/httpErrors";
import { StreamContext, StreamContextInterface } from "../context";
import { Stream } from "../types";
import { Group } from "../../Groups/types";

interface Props {}

interface State extends StreamContextInterface {}

class StreamContextProvider extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      streams: [],
      groups: []
    };
  }

  public componentDidMount(): void {
    getStreams()
      .then(throwHttpErros)
      .then(response => {
        const groups = response.reduce((list: Group[], stream: Stream) => {
          const newGroups =
            stream.groups.filter((group: Group) => {
              return !list.find((x: Group) => x.ID === group.ID);
            }, []) || [];
          return [...list, ...newGroups];
        }, []);
        this.setState({
          streams: response,
          groups
        });
      })
      .catch(catchHttpErros);
  }

  public componentWillUnmount(): void {
    this.setState({ streams: [], groups: [] });
  }

  public render() {
    return (
      <StreamContext.Provider value={this.state}>
        {this.props.children}
      </StreamContext.Provider>
    );
  }
}

export default StreamContextProvider;
