import * as React from "react";
import { Router as BrowserRouter, Route, Switch } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import Streams from "../Streams/Streams";
import Recordings from "../Recordings/Recordings";
import Team from "../Team/Team";
import Auth from "../Auth/Auth";
import Logout from "../Auth/Logout";
import PrivateRoute from "../Auth/PrivateRoute";
import Page from "../Page/Page";
import { history } from "./history";
import RecordingsStreamList from "../Recordings/RecordingsStreamList";
import { AuthContext } from "../Auth/context";

class Router extends React.Component {
  public renderRecordings = (props: any) => {
    return (
      <PrivateRoute>
        <Recordings streamId={props.match.params.id} />
      </PrivateRoute>
    );
  };

  public render() {
    return (
      <BrowserRouter history={history}>
        <Page>
          <Switch>
            <Route path="/" exact={true}>
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            </Route>
            <Route path="/team" exact={true}>
              <PrivateRoute>
                <AuthContext.Consumer>
                  {authContext =>
                    authContext &&
                    authContext.user && <Team user={authContext.user} />
                  }
                </AuthContext.Consumer>
              </PrivateRoute>
            </Route>
            <Route path="/recordings" exact={true}>
              <PrivateRoute>
                <RecordingsStreamList />
              </PrivateRoute>
            </Route>
            <Route
              path="/recordings/:id"
              exact={true}
              children={this.renderRecordings}
            />
            <Route path="/streams" exact={true}>
              <PrivateRoute>
                <Streams />
              </PrivateRoute>
            </Route>
            <Route path="/logout" exact={true}>
              <Auth>
                <Logout />
              </Auth>
            </Route>
          </Switch>
        </Page>
      </BrowserRouter>
    );
  }
}

export default Router;
