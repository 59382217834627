import * as React from "react";
import { Input as AntInput } from "antd";
import "./Input.scss";

interface Props {
  id: string;
  label?: string;
  placeholder?: string;
  value?: string;
  suffix?: string;
  disabled: boolean;
  allowClear: boolean;
  type?: string;
  onChange: (name: string, value: string) => void;
  onReturn?: () => void;
}

const Input = (props: Props) => {
  const { label, onReturn, ...rest } = props;

  const onChange = (e: any) => {
    props.onChange(e.target.id, e.target.value);
  };

  const onKeyDown = (e: any) => {
    if (props.onReturn && e.key === "Enter") {
      props.onReturn();
    }
  };

  const newProps = {
    ...rest,
    addonBefore:
      (
        <div className="input-label" title={label}>
          {label}
        </div>
      ) || undefined,
    onChange: rest.onChange ? onChange : undefined
  };

  return <AntInput onKeyDown={onKeyDown} {...newProps} />;
};

Input.defaultProps = {
  allowClear: false,
  disabled: false
};

export default Input;
