import { parseJson } from "./common";
import {HttpError} from "./httpErrors";
import * as storage from "./localStorage";

export function getStandardHeaders() {
    return {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${storage.get('token')}`,
    };
}

export function throwActionErrors(response: any) {
    if (!response.ok) {
        throw {
            ok: response.ok,
            status: response.status,
            statusText: response.statusText,
            body: parseJson(response.body),
        };
    }
    return response;
}

export function catchActionErrors(error: HttpError) {
    console.error("[api: auth()]", error);
    return error;
}

export function get(path: string, params: any = {}) {
    const options = {
        method: "GET",
        headers: {
            ...getStandardHeaders()
        },
        ...params
    };

    return fetch(path, options);
}

export function post(path: string, body: any = {}, params: any = {}) {
    const options = {
        method: "POST",
        headers: {
            ...getStandardHeaders()
        },
        body: JSON.stringify(body),
        ...params
    };

    return fetch(path, options);
}

export function put(path: string, body: any = {}, params: any = {}) {
    const options = {
        method: "PUT",
        headers: {
            ...getStandardHeaders()
        },
        body: JSON.stringify(body),
        ...params
    };

    return fetch(path, options);
}

export function del(path: string, params: any = {}) {
    const options = {
        method: "DELETE",
        headers: {
            ...getStandardHeaders()
        },
        ...params
    };

    return fetch(path, options);
}