import * as React from "react";
import { Icon as AntIcon, Menu as AntMenu } from "antd";
import { history } from "./history";
import { AuthContext } from "../Auth/context";

interface Props {
  some?: string;
}

interface State {
  route: string;
}

export default class Navigation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      route: history.location.pathname
    };
  }

  public onClick = (e: any) => {
    this.setState({
      route: e.key
    });
    history.push(e.key);
  };

  public render() {

    const routeDepth = this.state.route.split('/');
    const mainRoute = `/${routeDepth[1]}`;

    return (
      <AuthContext.Consumer>
        {authContext =>
          authContext && (
            <AntMenu
              onClick={this.onClick}
              selectedKeys={[this.state.route, mainRoute]}
              mode="horizontal"
            >
              <AntMenu.Item key="/">
                <AntIcon type="home" />
                Home
              </AntMenu.Item>
              <AntMenu.Item key="/recordings">
                <AntIcon type="caret-right" />
                Mitschnitte
              </AntMenu.Item>
                {authContext.user && authContext.user.permissions.includes('streams') ? (
                <AntMenu.Item key="/streams">
                  <AntIcon type="bars" />
                  Streams
                </AntMenu.Item>
              ) : null}
              {authContext.user && authContext.user.permissions.includes('team') ? (
                <AntMenu.Item key="/team">
                  <AntIcon type="team" />
                  Team
                </AntMenu.Item>
              ) : null}
              <AntMenu.SubMenu
                key="sub1"
                title={
                  <>
                    <AntIcon type="user" />
                    Mein Account
                  </>
                }
              >
                <AntMenu.Item key="/logout">
                  <AntIcon type="logout" />
                  Logout
                </AntMenu.Item>
              </AntMenu.SubMenu>
            </AntMenu>
          )
        }
      </AuthContext.Consumer>
    );
  }
}
