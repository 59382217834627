import * as React from 'react';
import './Auth.scss';

interface Props {
    children: React.ReactNode;
}

const Auth = (props: Props) => (
    <div className="auth">
        <div className="auth__content">
            {props.children}
        </div>
        <div className="auth__footer">
            <a href="https://www.streamabc.com/imprint" target="_blank">Impressum</a>
            <a href="https://www.streamabc.com/de/datenschutz" target="_blank">Datenschutz</a>
        </div>
    </div>
);

export default Auth;