import * as React from 'react';
import { Checkbox } from 'antd';
import { CheckboxGroupProps } from "node_modules/antd/lib/checkbox";
import "./CheckboxGroup.scss";

interface Props extends CheckboxGroupProps {}

const AntCheckboxGroup = Checkbox.Group;

const CheckboxGroup = (props: Props) => {
    return <AntCheckboxGroup className="checkbox-group" {...props} />;
};

CheckboxGroup.defaultProps = {};

export default CheckboxGroup;
