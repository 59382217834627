import * as React from "react";
import Content from "../../components/organisms/Content/Content";
import RecordingsDashboard from "../Recordings/DashboardTool";

class Dashboard extends React.Component {
  public render() {
    return <Content>
      <h1>Stream Recorder</h1>
      <RecordingsDashboard />
    </Content>;
  }
}

export default Dashboard;
